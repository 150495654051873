import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../block/container"
import ImageTitleText from "../item/imageTitleText"
import FieldText from "../field/fieldText"
import "../../styles/components/block--info.scss"

const BlockInfo = () => {
  return (
    <Container classes="block--info">
      <ImageTitleText
        title="Eliminate annoying e-mail threads"
        classes="image-title-text--threads"
      >
        <StaticImage
          as="figure"
          className="field field--image"
          src="../../assets/images/eliminate-annoying-email-threads.png"
          quality="90"
          placeholder="blurred"
          alt="Info image"
          title="Info image"
        />
        <FieldText>
          <p>
            Clients submit their Request through a custom intake form, which
            gathers up all of the necessary information. No more back and forth!
          </p>
        </FieldText>
      </ImageTitleText>

      <ImageTitleText
        title="Know where to focus"
        classes="image-title-text--focus"
      >
        <StaticImage
          as="figure"
          className="field field--image"
          src="../../assets/images/know-where-to-focus.png"
          quality="90"
          placeholder="blurred"
          alt="Info image"
          title="Info image"
        />
        <FieldText>
          <p>
            Requests are automatically assigned to the right person, and
            prioritized according to due date and urgency.
          </p>
        </FieldText>
      </ImageTitleText>

      <ImageTitleText
        title="Increase transparency"
        classes="image-title-text--transparency"
      >
        <StaticImage
          as="figure"
          className="field field--image"
          src="../../assets/images/increase-transparency.png"
          quality="90"
          placeholder="blurred"
          alt="Info image"
          title="Info image"
        />
        <FieldText>
          <p>
            Clients are always aware of who's working on their Requests and
            what's coming next. No need for those tedious status calls.
          </p>
        </FieldText>
      </ImageTitleText>

      <ImageTitleText
        title="Create a new revenue team"
        classes="image-title-text--revenue"
      >
        <StaticImage
          as="figure"
          className="field field--image"
          src="../../assets/images/create-a-new-revenue-stream.png"
          quality="90"
          placeholder="blurred"
          alt="Info image"
          title="Info image"
        />
        <FieldText>
          <p>
            Clients are more likely to utilize your services when you make it
            easy for them to do so. Sideline makes it fun and easy. Get more
            retainer clients and more billable hours!
          </p>
        </FieldText>
      </ImageTitleText>
    </Container>
  )
}

export default BlockInfo
