import * as React from "react"

const ImageTitleText = ({ children, title, classes }) => (
  <div className={`image-title-text${classes ? ` ${classes}` : ""}`}>
    <h3 className="field field--title">{title}</h3>
    {children}
  </div>
)

export default ImageTitleText
