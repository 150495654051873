import * as React from "react"
import { navigate } from "gatsby"
import { useGlobalContext } from "../../context/context"

const Price = ({
  children,
  special = false,
  priceType,
  priceSign,
  price,
  link,
  linkLabel,
  productID,
  period,
}) => {
  const { userInfo, setUserInfo, setPackageError } = useGlobalContext()
  const handleClick = () => {
    setUserInfo({
      ...userInfo,
      features_package: productID,
    })
    setPackageError(false)
    navigate(link)
  }

  return (
    <div className={`price-item${special ? " price-item--saving" : ""}`}>
      {special ? <div className="price-special">Save 20% Annually</div> : null}
      <div className="price-info">
        <h4 className="price-type">{priceType}</h4>
        <p className="price-value">
          {priceSign ? <sup>{priceSign}</sup> : null}
          {price}
        </p>
        <p>User/{period}</p>
        <p>Starts with 2 users</p>
      </div>
      <ul className="price-details">{children}</ul>
      <a
        onClick={handleClick}
        className="button button--medium button--white button--pricing"
      >
        {linkLabel}
      </a>
    </div>
  )
}

export default Price
