import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/regions/header"
import Container from "../components/block/container"
import SwitchButton from "../components/item/switchButton"
import FieldText from "../components/field/fieldText"
import Price from "../components/item/price"
import Accordion from "../components/item/accordion"
import FieldAccordion from "../components/field/fieldAccordion"
import BlockInfo from "../components/full/blockInfo"
import BlockClients from "../components/full/blockClients"
import BlockTestimonials from "../components/full/blockTestimonials"
import "../styles/components/block--page-top-content--pricing.scss"
import "../styles/components/block--pricing-list.scss"
import "../styles/components/block--common-questions.scss"
import "../styles/components/block--start-using.scss"
import "../styles/components/price-type-switch.scss"
import { useGlobalContext } from "../context/context"

const PricingPage = () => {
  const { subscriptionType } = useGlobalContext()

  return (
    <>
      <Header />
      <Layout>
        <Seo
          title="Pricing"
          description="Get started with Sideline for Free."
          pageClass="page--pricing"
        />

        <Container classes="block--page-top-content block--page-top-content--pricing">
          <FieldText>
            <h3 className="text text--red text--uppercase text--centered">
              Pricing
            </h3>
            <h1 className="text text--centered">
              Get started with Sideline for Free
            </h1>
          </FieldText>

          <Container classes="block--pricing-list">
            <SwitchButton />
            <Price
              special={false}
              link="/sign-up"
              linkLabel="Get Started For Free"
              priceType="Basic"
              priceSign="$"
              productID={
                subscriptionType === "monthly"
                  ? "prod_LULQG1tG1OUbtt"
                  : "prod_LajI3KDIdro1EB"
              }
              price={subscriptionType === "monthly" ? "49" : "588"}
              period={subscriptionType === "monthly" ? "month" : "year"}
            >
              <li>All Standard features</li>
              <li>25 Mailboxes</li>
              <li>Custom fields and Teams</li>
              <li>All integrations</li>
              <li>Lorem Ipsum Dolor</li>
              <li>Brandable Content</li>
            </Price>

            <Price
              special={true}
              link="/sign-up"
              linkLabel="Start Using Sideline"
              priceType="Pro"
              priceSign="$"
              productID={
                subscriptionType === "monthly"
                  ? "prod_LULQpgfnYLjXES"
                  : "prod_Laj85RID72pOt1"
              }
              price={subscriptionType === "monthly" ? "149" : "1430"}
              period={subscriptionType === "monthly" ? "month" : "year"}
            >
              <li>All Standard features</li>
              <li>25 Mailboxes</li>
              <li>Custom fields and Teams</li>
              <li>All integrations</li>
              <li>Lorem Ipsum Dolor</li>
              <li>Brandable Content</li>
            </Price>

            <Price
              link="/contact"
              linkLabel="Contact Us"
              priceType="Company"
              priceSign={false}
              price="Contact Us"
            >
              <li>All Standard features</li>
              <li>25 Mailboxes</li>
              <li>Custom fields and Teams</li>
              <li>All integrations</li>
              <li>Lorem Ipsum Dolor</li>
              <li>Brandable Content</li>
            </Price>
          </Container>
        </Container>

        <BlockInfo />

        <BlockTestimonials />

        <BlockClients />

        <Container classes="block--common-questions" title="Common Questions">
          <FieldAccordion>
            <Accordion title="What happens when i start a free trial?">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id
                nobis omnis voluptate voluptatem voluptatibus! Commodi, debitis.
              </p>
            </Accordion>

            <Accordion title="What's the setup process like?">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Commodi consequuntur dolorem eligendi.
              </p>
            </Accordion>

            <Accordion title="Are there migration services available?">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Dolorum, explicabo minus nulla numquam quibusdam repellendus
                saepe temporibus vitae.
              </p>
            </Accordion>

            <Accordion title="What type of support is offered?">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Asperiores.
              </p>
            </Accordion>
          </FieldAccordion>
        </Container>

        <Container classes="block--start-using">
          <FieldText>
            <h2 className="text text--centered">Let us be your nerd.</h2>
            <p className="text text--centered">
              We'll take you on a quick, no-pressure tour of our tools. You'll
              learn why thousands of marketers are graduating from typical email
              platforms, and making more money with Sideline.
            </p>
            <Link to="/sign-up" className="button button--big button--white">
              Start Using Sideline
            </Link>
          </FieldText>
        </Container>
      </Layout>
    </>
  )
}

export default PricingPage
