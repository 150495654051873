import React from "react"
import classNames from "classnames"
import { useGlobalContext } from "../../context/context"

const SwitchButton = () => {
  const {
    userInfo,
    setUserInfo,
    subscriptionType,
    setSubscriptionType,
  } = useGlobalContext()

  const handleClick = type => {
    setUserInfo({ ...userInfo, features_package: "", price: "" })

    setSubscriptionType(type)
  }
  return (
    <div className="price-type-switch">
      <div className="price-type-switch-inner">
        <div className="price-save-tooltip">Save 20%</div>
        <div className="price-type-toggle">
          <div
            className={classNames("price-type-slider", {
              "is-active": subscriptionType === "monthly",
            })}
          />
          <button
            onClick={() => handleClick("monthly")}
            className={classNames("button button--transparent", {
              "button--red is-active": subscriptionType === "monthly",
            })}
          >
            Monthly
          </button>
          <button
            onClick={() => handleClick("annual")}
            className={classNames("button button--transparent", {
              "button--red is-active": subscriptionType === "annual",
            })}
          >
            Annually
          </button>
        </div>
      </div>
    </div>
  )
}

export default SwitchButton
