import React, { useRef, useState } from "react"
import PropTypes from "prop-types"

const Accordion = ({ children, title, open = false }) => {
  const [accordionState, setOpen] = useState(open)
  const [setHeight, setHeightState] = useState("0px")
  const content = useRef(null)
  const handleAccordion = () => {
    setOpen(prev => !prev)
    setHeightState(
      accordionState === true ? "0px" : `${content.current.scrollHeight}px`
    )
  }

  return (
    <div className={`item${accordionState ? " open" : ""}`}>
      <div className="item__header">
        <button
          type="button"
          aria-expanded={accordionState}
          onClick={handleAccordion}
        >
          {title}
          <i className="icon icon--chevron-black-down" />
        </button>
      </div>
      <div
        ref={content}
        className="item__content"
        style={{ height: `${setHeight}` }}
      >
        {children}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Accordion
